<template>
  <el-dialog v-model="_show" :append-to-body="true">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("common.desc4") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="popup-scroll">
        <div class="con">
          <div class="msg">
            <img src="../public/images/icon_succ.png" />
            <h2>{{ title }}</h2>
            <p>{{ content }}</p>
          </div>
        </div>
        <div class="foot">
          <div class="btn-con">
            <!-- <div class="btn cancel" @click="_show = false">
              {{ $t("common.desc2") }}
            </div> -->
            <div class="btn confirm" @click="_show = false">
              {{ $t("common.desc3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "succ",
    },
    show: Boolean,
    title: String,
    content: String,
  },
  data() {
    return {};
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
};
</script>
<style lang="less" scoped>
.popup-con {
  width: 100%;
  min-width: 416px;
  border-radius: 12px;
  background: #181818;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .popup-scroll {
    width: 100%;
    padding: 0 40px;
    max-height: 85vh;
  }
  .head {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;

    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../public/images/icon_close.png") no-repeat center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .con {
    .msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
      h2 {
        margin-top: 20px;
        color: #fff;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: capitalize;
      }
      p {
        margin-top: 12px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .foot {
    .btn-con {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .btn {
        width: 100%;
        max-width: 300px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &.cancel {
          color: #fff;
          background-image: url("../public/images/asset/assetbag/pupup_btn1_bg.png");
        }
        &.confirm {
          color: #000;
          background-image: url("../public/images/asset/assetbag/btn1_bg.png");
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .popup-con {
    min-width: 327px;
    padding: 20px 0;
    .popup-scroll {
      padding: 0 20px;
    }
    .head {
      margin-bottom: 20px;
      padding: 0 20px;
      .title {
        font-size: 18px;
      }
    }
    .con {
      .msg {
        img {
          width: 54px;
          height: 54px;
        }
        h2 {
          font-size: 20px;
          line-height: 20px;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
    .foot {
      .btn-con {
        margin-top: 20px;
        .btn {
          // max-width: 48%;
          width: 200px;
          height: 48px;
          font-size: 14px;
          &.confirm {
            background-image: url("../public/images/asset/assetbag/pupup_btn2_bg.png");
          }
        }
      }
    }
  }
}
</style>
